import Axios from 'axios';
import { authorizationHeader } from './apiUtils';
import { ImportLog, RejectedDiamond } from '../models/misc';

const routes = {
  importLogs: '/api/logs/importLogs',
};

export const getAllLogs = async (): Promise<ImportLog[]> => {
  const result = await Axios.get<ImportLog[]>(routes.importLogs, {
    headers: authorizationHeader(),
  });
  return result.data;
};

export const fetchRejectedDiamonds = async (importLogId: number): Promise<RejectedDiamond[]> => {
  try {
    const response = await Axios.get<RejectedDiamond[]>(`${routes.importLogs}/${importLogId}/rejectedDiamonds`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching rejected diamonds:', error);
    throw error;
  }
};

export const deleteLogByVendorName = async (vendorName: string): Promise<void> => {
  await Axios.delete(`${routes.importLogs}/${encodeURIComponent(vendorName)}`, {
    headers: authorizationHeader(),
  });
};
