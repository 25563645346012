import Axios from 'axios';
import { authorizationHeader } from './apiUtils';
import { UpdateVendorData, Vendor } from '../models/misc';

const routes = {
  vendors: '/api/vendors',
};

const getAllVendors = async (): Promise<Vendor[]> => {
  const result = await Axios.get<Vendor[]>(routes.vendors, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const editVendor = async (vendor: UpdateVendorData): Promise<Vendor> => {
  const route = `/api/vendors/${encodeURIComponent(vendor.vendorName)}`;
  const result = await Axios.put<Vendor>(route, vendor, {
    headers: authorizationHeader(),
  });
  return result.data;
};



export { getAllVendors, editVendor };
