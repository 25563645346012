import { createActionCreator, createReducer } from 'deox';
import { Dispatch } from 'redux';
import * as vendorService from '../../services/vendors';
import { withErrorDispatch } from './withErrorDispatch';
import { actions as messageActions } from './messages';
import { UpdateVendorData, Vendor } from '../../models/misc';

export type State = Vendor[];

const defaultState: State = [];

const fetchAllVendorsSuccess = createActionCreator(
    '@@ADMIN/VENDORS/FETCH_ALL_SUCCESS',
    (resolve) => (vendors: Vendor[]) => resolve(vendors)
);

const fetchAllVendors = () =>
    withErrorDispatch(
        vendorService.getAllVendors(),
        (data: Vendor[]) => fetchAllVendorsSuccess(data),
        'Error fetching vendors'
    );


const editVendorSuccess = createActionCreator(
    '@@ADMIN/VENDORS/EDIT_SUCCESS',
    (resolve) => (vendor: Vendor) => resolve(vendor)
);

const editVendor = (vendor: UpdateVendorData) =>
    withErrorDispatch(
      vendorService.editVendor(vendor),
      (data: Vendor) => editVendorSuccess(data),
      'Error editing vendor'
    );



const reducer = createReducer(defaultState, (handleAction) => [
    handleAction(fetchAllVendorsSuccess, (state, action) => action.payload),
    handleAction(editVendorSuccess, (state, action) =>
        state.map((v) => (v.id === action.payload.id ? action.payload : v))),
]);



const actions = {
    fetchAllVendors,
    editVendor,
};

export { actions, reducer };