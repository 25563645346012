import { createActionCreator, createReducer } from 'deox';
import { Dispatch } from 'redux';
import * as logsService from '../../services/logs';
import { withErrorDispatch } from './withErrorDispatch';
import { actions as messageActions } from './messages';
import { ImportLog } from '../../models/misc';

export type State = ImportLog[];

const defaultState: State = [];

// Action Creators
const fetchAllLogsSuccess = createActionCreator(
  '@@ADMIN/LOGS/FETCH_ALL_SUCCESS',
  (resolve) => (logs: ImportLog[]) => resolve(logs)
);

const deleteLogSuccess = createActionCreator(
  '@@ADMIN/LOGS/DELETE_SUCCESS',
  (resolve) => (vendorName: string) => resolve(vendorName)
);

const fetchAllLogs = () =>
  withErrorDispatch(
    logsService.getAllLogs(),
    (data: ImportLog[]) => fetchAllLogsSuccess(data),
    'Error fetching logs'
  );

const deleteLog = (vendorName: string) =>
  withErrorDispatch(
    logsService.deleteLogByVendorName(vendorName),
    () => deleteLogSuccess(vendorName),
    'Error deleting log'
  );

// Reducer
const reducer = createReducer(defaultState, (handleAction) => [
  handleAction(fetchAllLogsSuccess, (state, action) => action.payload),
  handleAction(deleteLogSuccess, (state, action) =>
    state.filter((log) => log.vendorName !== action.payload)
  ),
]);


const actions = {
  fetchAllLogs,
  deleteLog
};

export { actions, reducer };
